<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import { Doughnut } from "vue-chartjs";

Chart.defaults.global.defaultFontFamily = "'Montserrat'";
Chart.defaults.global.defaultFontSize = 11;

export default {
  // name: "BarChart",
  date() {
    return {
      vendas_device: [],
    };
  },
  props: {
    vendas_device: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    vendas_device() {
      this.mountedChart();
    },
  },
  extends: Doughnut,
  methods: {
    mountedChart() {
      this.gradient = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);

      this.gradient.addColorStop(0, "rgba(0,33,99,.4)");
      this.gradient.addColorStop(0.5, "rgba(0,33,99,1)");
      this.gradient.addColorStop(1, "rgba(0,33,99,1)");

      this.renderChart(
        {
          labels: [...this.vendas_device.map((item) => item.device)],
          datasets: [
            {
              label: "Vendas por Dispositivos",
              backgroundColor: [this.gradient, "#091E3E"],
              borderColor: "#fff",
              borderWidth: 0,
              data: [...this.vendas_device.map((item) => item.count)],
            },
          ],
        },
        {
          legend: {
            position: "bottom",
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const total = dataset.data.reduce(function(
                  previousValue,
                  currentValue
                ) {
                  return previousValue + currentValue;
                });
                const currentValue = dataset.data[tooltipItem.index];
                // return `R$ ${currentValue}`;
                return currentValue.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                });
              },
            },
          },
          cutoutPercentage: 85,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                  min: 0,
                  stepSize: 2,
                  callback: function(value, index, values) {
                    return value.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    });
                  },
                },
              },
            ],
          },
        }
      );
    },
  },
  mounted() {
    this.mountedChart();
  },
};
</script>
