<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import moment from "moment";

import { Line } from "vue-chartjs";
import Money from "@/mixins/money";

Chart.defaults.global.defaultFontFamily = "'Montserrat'";
Chart.defaults.global.defaultFontSize = 11;

export default {
  name: "AreaChart",
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient2: null,
      vendas_semana: [],
    };
  },
  mixins: [Money],
  methods: {
    mountedChart(vendas_semana) {
      this.vendas_semana = vendas_semana;
      this.gradient = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);

      this.gradient.addColorStop(0, "rgba(0,35,99,1)");
      this.gradient.addColorStop(0.5, "rgba(0,35,99,.2)");
      this.gradient.addColorStop(1, "rgba(0,35,99,0)");

      this.renderChart(
        {
          labels: [
            ...this.vendas_semana
              .reverse()
              .map((item) => moment(item.date).format("DD/MM")),
          ],
          datasets: [
            {
              label: "Vendas últimos 7 dias / R$",
              borderColor: "#002363",
              pointBackgroundColor: "#FFF",
              borderWidth: 1.5,
              pointBorderColor: "#002363",
              backgroundColor: this.gradient,
              data: [
                ...this.vendas_semana
                  // .reverse()
                  .map((item) => item.total),
              ],
            },
          ],
        },
        {
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var total = dataset.data.reduce(function(
                  previousValue,
                  currentValue
                ) {
                  return previousValue + currentValue;
                });
                var currentValue = dataset.data[tooltipItem.index];
                return currentValue.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                });
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: "#f7f7f7",
                },
                ticks: {
                  min: 0,
                  display: true,
                  // stepSize: 100,
                },
              },
            ],
          },
        }
      );
    },
  },
  mounted() {
    // this.loading = true;
    // this.getDadosGrafico();
  },
};
</script>
